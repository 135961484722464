<template>
  <v-dialog
    v-model="dialogAddPostagem"
    @click:outside="$emit('update:dialogAddPostagem', false)"
    @keydown.esc="$emit('update:dialogAddPostagem', false)"
    scrollable
    fullscreen
  >
    <v-card tile :loading="loading" :disabled="loading">
      <v-card-title class="pa-4 font-weight-bold accent">
        {{ edit ? "Editar" : "Criar" }} Postagem
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogAddPostagem', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <!-- Data de postagem-->
            <v-col class="pt-6" cols="12" md="4">
              <h2 class="mb-4 text-h6">Dados</h2>
              <v-row>
                <!-- Data -->
                <v-col cols="12" md="6">
                  <DataField
                    label="Data"
                    :data_sync.sync="calendarioPostagem.data"
                    obrigatorio
                    :data_min="data_inicio"
                    :data_max="data_fim"
                  />
                </v-col>

                <!-- Hora de Postagem -->
                <v-col cols="12" md="6">
                  <v-menu
                    ref="menu_hora"
                    v-model="menu_hora"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="calendarioPostagem.hora"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="calendarioPostagem.hora"
                        label="Hora:"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="formRules"
                        required
                        dense
                        clearable
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu_hora"
                      v-model="calendarioPostagem.hora"
                      full-width
                      @click:minute="
                        $refs.menu_hora.save(calendarioPostagem.hora)
                      "
                      format="24hr"
                    ></v-time-picker>
                  </v-menu>
                </v-col>

                <!-- Assunto da Postagem -->
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="calendarioPostagem.assunto"
                    :rules="[rules.required, rules.max]"
                    placeholder="Insira o assunto..."
                    counter="255"
                    max="255"
                    required
                    auto-grow
                    :rows="4"
                  ></v-textarea>
                </v-col>

                <v-col cols="12" md="12">
                  <v-autocomplete
                    v-model="selectedServico"
                    :items="cliente_servicos"
                    :loading="loadingServicos"
                    dense
                    hide-no-data
                    item-text="servico"
                    item-value="id"
                    label="Serviço"
                    prepend-inner-icon="mdi-magnify"
                    return-object
                    required
                    :rules="formRules"
                  ></v-autocomplete>
                </v-col>

                <!-- Formato da Postagem -->
                <v-col cols="12" md="12">
                  <FormatoField
                    v-if="!loading"
                    :item_sync.sync="calendarioPostagem.formato_id"
                    :edit="edit"
                    :formato="calendarioPostagem.formato"
                    label="Formato"
                    obrigatorio
                  />
                </v-col>

                <!-- Tema da Postagem -->
                <v-col cols="12" md="12">
                  <TemaField
                    v-if="!loading"
                    :item_sync.sync="calendarioPostagem.tema_id"
                    :edit="edit"
                    :temaProp="calendarioPostagem.tema"
                    label="Tema"
                    obrigatorio
                  />
                </v-col>
              </v-row>
            </v-col>

            <!-- Redes Sociais -->
            <v-col cols="12" md="" class="pt-0 pb-0">
              <v-row>
                <v-col
                  cols="12"
                  md="auto"
                  class="d-flex align-center justify-center pa-0"
                >
                  <v-divider vertical></v-divider>
                </v-col>

                <!-- Todas as Redes Sociais -->
                <v-col cols="12" md="6" class="pt-6">
                  <h2 class="mb-2 mt-4 text-h6">Redes Sociais</h2>
                  <v-card
                    v-for="(redeSocial, index) in redesSociais"
                    :key="index"
                    class="mb-2"
                    flat
                    color="card-rede-social"
                    @click="selectedRedeSocial(index, redeSocial)"
                    style="position: relative"
                  >
                    <v-card-title class="text-subtitle-1 py-2 px-3">
                      <div
                        style="width: 30px; height: 30px"
                        class="d-flex justify-center align-center mr-3"
                      >
                        <v-img contain :src="redeSocial.icone"></v-img>
                      </div>
                      {{ redeSocial.nome }}
                      <v-spacer></v-spacer>
                      <v-btn
                        @click.stop="editRedeSocial(redeSocial)"
                        icon
                        small
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text class="px-3 pb-2">
                      <h6>locais cadastrados</h6>
                      <v-chip-group column>
                        <v-chip
                          v-for="(local, index) in redeSocial.locais"
                          :key="index"
                          label
                          small
                          class="mr-2"
                        >
                          {{ local.descricao }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                    <v-icon
                      color="grey lighten-2"
                      style="position: absolute; bottom: 5px; right: 5px"
                      size="40"
                    >
                      mdi-arrow-right-thick
                    </v-icon>
                  </v-card>
                  <!--Adicionar Rede Social -->
                  <v-btn @click="addRedeSocial" text x-small>
                    <v-icon small>mdi-plus</v-icon>
                    rede social
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  md="auto"
                  class="d-flex align-center justify-center pa-0"
                >
                  <v-divider vertical></v-divider>
                </v-col>

                <!-- Redes Sociais Selecionadas -->
                <v-col cols="12" md="" class="pt-6">
                  <h2 class="text-h6 mt-4 mb-2">Redes Sociais Selecionadas</h2>
                  <template v-if="redesSociaisSelecionadas.length">
                    <v-card
                      v-for="(redeSocial, index) in redesSociaisSelecionadas"
                      :key="index"
                      class="mb-2"
                      flat
                      color="card-rede-social"
                      @click="deletedRedeSocial(index, redeSocial)"
                      style="position: relative"
                    >
                      <v-card-title class="text-subtitle-1 py-2 px-3">
                        <div
                          style="width: 30px; height: 30px"
                          class="d-flex justify-center align-center mr-3"
                        >
                          <v-img contain :src="redeSocial.icone"></v-img>
                        </div>
                        {{ redeSocial.nome }}
                        <v-spacer></v-spacer>
                      </v-card-title>
                      <v-card-text class="px-3 pb-2">
                        <h6>selecione o local da postagem</h6>
                        <v-chip-group
                          v-model="selectedLocaisChips"
                          column
                          multiple
                        >
                          <v-chip
                            v-for="(local, indexChip) in redeSocial.locais"
                            :key="indexChip"
                            class=""
                            label
                            small
                            filter
                            @click.stop
                            :value="local.redes_sociais_locais_id"
                            >{{ local.descricao }}
                          </v-chip>
                        </v-chip-group>
                      </v-card-text>
                      <v-icon
                        color="grey lighten-2"
                        style="position: absolute; bottom: 5px; right: 5px"
                        size="40"
                      >
                        mdi-arrow-left-thick
                      </v-icon>
                    </v-card>
                  </template>
                  <div v-else>Nenhuma rede social selecionada!</div>
                  <div></div>
                  <v-alert
                    dense
                    text
                    type="error"
                    v-if="nenhumLocalErro"
                    class="mt-6"
                  >
                    Por favor, selecione
                    {{
                      !redesSociaisSelecionadas.length
                        ? "uma rede social e"
                        : ""
                    }}
                    um local
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn @click="$emit('update:dialogAddPostagem', false)">
          {{ edit ? "voltar" : "cancelar" }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="edit ? editPostagem() : addPostagem()"
          class="white--text"
          color="secondary"
        >
          {{ edit ? "Salvar" : "Adicionar" }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <AddEditRedeSocial
      v-if="dialogEditRedeSocial"
      :dialogEditRedeSocial.sync="dialogEditRedeSocial"
      @fetch="getRedesSociais"
      :item="redeSocial"
      :add="add"
    />
  </v-dialog>
</template>

<script>
import { inputRequired } from "@/helpers/utils.js";
import {
  postCalendarioPostagem,
  fetchCalendarioPostagem,
  putCalendarioPostagem,
} from "@/api/colaborador/midias_sociais_calendario_postagem.js";
import { fetchRedesSociais } from "@/api/colaborador/midias_sociais_redes_sociais.js";
import { fetchClienteServicos } from "@/api/colaborador/cliente_servicos.js";
import { mapState } from "vuex";

export default {
  name: "AddEditPostagemMidiaSociais",

  props: {
    dialogAddPostagem: {
      type: Boolean,
      required: true,
    },
    data_inicio: {
      type: String,
    },
    data_fim: {
      type: String,
    },
    postagem_id: {
      type: [Number, String],
      default: null,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    calendario: {
      type: Object,
    },
  },

  components: {
    DataField: () =>
      import("../../../../../../components/fields/DataField.vue"),
    FormatoField: () =>
      import("../../../../../../components/postagem_formato/FormatoField.vue"),
    TemaField: () =>
      import("../../../../../../components/postagem_tema/TemaField.vue"),
    AddEditRedeSocial: () => import("./AddEditRedeSocial.vue"),
  },

  data() {
    return {
      dialogEditRedeSocial: false,
      selectedServico: null,
      cliente_servicos: [],
      loadingServicos: false,
      menu_hora: false,
      loading: false,
      calendarioPostagem: {
        status: 1,
      },
      redesSociais: [],
      redeSocial: {},
      redesSociaisSelecionadas: [],
      selectedLocaisChips: [],
      validForm: true,
      formRules: [inputRequired],
      diasValidos: [],
      nenhumLocalErro: false,
      add: false,
      rules: {
        required: (value) => !!value || "obrigatório",
        max: (v) => (v && v.length <= 255) || "max 255 caracteres",
      },
    };
  },

  watch: {
    selectedLocaisChips() {
      if (this.selectedLocaisChips.length && this.nenhumLocalErro) {
        this.nenhumLocalErro = false;
      }
    },
    selectedServico() {
      if (this.selectedServico) {
        this.calendarioPostagem.servico_id = this.selectedServico.servico_id;
      }
    },
  },

  computed: {
    postagem_calendario_id() {
      return this.$route.params.calendarioId;
    },
    ...mapState("Usuario", {
      colaborador_id: (state) => state.usuario.colaborador_id,
    }),
  },

  methods: {
    addPostagem() {
      if (this.$refs.form.validate() && this.selectedLocaisChips.length) {
        this.loading = true;

        let calendarioPostagem = this.calendarioPostagem;
        calendarioPostagem.formato_id = this.calendarioPostagem.formato_id;
        calendarioPostagem.tema_id = this.calendarioPostagem.tema_id;
        calendarioPostagem.calendario_id = this.postagem_calendario_id;

        calendarioPostagem.locais = this.selectedLocaisChips;
        calendarioPostagem.colaborador_id = this.colaborador_id;

        postCalendarioPostagem(calendarioPostagem)
          .then(() => {
            this.$toast.success("Postagem criado com sucesso!");
            this.$emit("fetch-postagem");
            this.$emit("update:dialogAddPostagem", false);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        if (!this.selectedLocaisChips.length) {
          this.nenhumLocalErro = true;
        }
      }
    },

    editPostagem() {
      if (this.$refs.form.validate() && this.selectedLocaisChips.length) {
        this.loading = true;

        let calendarioPostagem = {};
        calendarioPostagem.id = this.calendarioPostagem.id;
        calendarioPostagem.formato_id = this.calendarioPostagem.formato_id;
        calendarioPostagem.tema_id = this.calendarioPostagem.tema_id;
        calendarioPostagem.data = this.calendarioPostagem.data;
        calendarioPostagem.hora = this.calendarioPostagem.hora;
        calendarioPostagem.assunto = this.calendarioPostagem.assunto;
        calendarioPostagem.calendario_id = this.postagem_calendario_id;
        calendarioPostagem.servico_id = this.calendarioPostagem.servico_id;
        calendarioPostagem.locais = this.selectedLocaisChips;

        putCalendarioPostagem(calendarioPostagem.id, calendarioPostagem)
          .then(() => {
            this.$toast.success("Postagem editado com sucesso!");
            this.$emit("fetch-postagem");
            this.$emit("update:dialogAddPostagem", false);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        if (!this.selectedLocaisChips.length) {
          this.nenhumLocalErro = true;
        }
      }
    },

    addRedeSocial() {
      this.dialogEditRedeSocial = true;
      this.add = true;
    },

    editRedeSocial(item) {
      this.redeSocial = { ...item };
      this.dialogEditRedeSocial = true;
      this.add = false;
    },

    getRedesSociais() {
      return fetchRedesSociais().then((response) => {
        let copy_response = [...response];

        if (this.redesSociaisSelecionadas.length) {
          this.redesSociaisSelecionadas.forEach((redeSocial) => {
            const index = copy_response.findIndex(
              (item) => item.id === redeSocial.id
            );
            if (index !== -1) copy_response.splice(index, 1);
          });
        }

        this.redesSociais = copy_response;
      });
    },

    selectedRedeSocial(index, redeSocial) {
      this.redesSociais.splice(index, 1);
      redeSocial.removedIndex = index;
      this.redesSociaisSelecionadas.push(redeSocial);
    },

    deletedRedeSocial(index, redeSocial) {
      let redes_sociais_locais_id = redeSocial.locais.map((item) => {
        return item.redes_sociais_locais_id;
      });
      this.selectedLocaisChips = this.selectedLocaisChips.filter(
        (item) => !redes_sociais_locais_id.includes(item)
      );
      redeSocial.addedIndex = index;
      this.redesSociaisSelecionadas.splice(index, 1);
      this.redesSociais.splice(redeSocial.removedIndex, 0, redeSocial);
    },

    getPostagem() {
      return fetchCalendarioPostagem(this.postagem_id).then((response) => {
        this.calendarioPostagem = response;
        this.redesSociaisSelecionadas = this.calendarioPostagem.redes_sociais;
        this.selectedLocaisChips = this.calendarioPostagem.locais;

        let redes_sociais = this.redesSociaisSelecionadas.map((item) => {
          return item.id;
        });

        this.redesSociais = this.redesSociais.filter(
          (item) => !redes_sociais.includes(item.id)
        );
      });
    },
    getClienteServicos(cliente_id) {
      this.loadingServicos = true;
      return fetchClienteServicos(cliente_id)
        .then((response) => {
          this.cliente_servicos = response;
          this.cliente_servicos.forEach((servico) => {
            if (this.edit) {
              if (servico.servico_id == this.calendarioPostagem.servico_id) {
                this.selectedServico = { ...servico };
              }
            } else if (!this.edit) {
              if (servico.servico_id == this.calendario.servico_id) {
                this.selectedServico = { ...servico };
              }
            }
          });
          this.loadingServicos = false;
        })
        .catch(() => {
          this.loadingServicos = false;
        });
    },
  },

  async created() {
    try {
      this.loading = true;
      await this.getRedesSociais();
      if (this.edit) {
        await this.getPostagem();
      }
      await this.getClienteServicos(this.calendario.cliente_id);
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style lang="scss" scoped>
.card-rede-social {
  background-color: #fafafa !important;
  &:hover {
    background-color: #eeeeee !important;
  }
}
</style>
